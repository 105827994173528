<template>
  <div>
    <office-header></office-header>
    <router-view></router-view>
    <office-footer></office-footer>
  </div>
</template>

<script>
import headerComponent from "./components/Header";
import footerComponent from "./components/Footer";

export default {
  inject: {
    service: "service"
  },
  components: {
    officeHeader: headerComponent,
    officeFooter: footerComponent
  },
  methods: {
    async getNewestAsync() {
      var response = await this.service.getHistoryDataAsync();     
      if (response && response.length > 0) {
        this.$store.commit("updateIssueNewest", { k3: response });
      }
    }
  },
  async created() {
    await this.getNewestAsync();
  }
};
</script>