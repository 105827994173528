<template>
  <!-- 背景 -->
  <div class="banner">
    <div class="container">
      <img class="banner_web" src="images/info_main.png" alt="" />
      <img class="banner_mobile" src="images/info_main_mobile.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>