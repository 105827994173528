import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router';
import service from './service';
import i18n from './i18n';

Vue.use(i18n);
Vue.config.productionTip = false;
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    issueNewest: {
      k3: {}
    }
  },
  mutations: {
    updateIssueNewest(state, value) {
      if (value && value.k3) {
        state.issueNewest.k3 = value.k3;
      }
    }
  }
})

new Vue({
  provide() {
    return { service };
  },
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')

