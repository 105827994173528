<template>
  <div class="footer">
    <div class="container">
      FAMILLE, VIE SOCIALE, SANTÉ FINANCIÈRE, ÊTES-VOUS PRÊT Á TOUT MISER ?POUR
      ÊTRE AIDÉ,
      <a href="mailto:contact@k3loto.fr" style="color: white">NOUS CONTACTER</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>