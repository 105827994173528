<template>
  <!-- 背景 -->
  <div class="winner">
    <div class="container">
      <div class="title">Notre <span>gagnants</span></div>
      <div class="k3_divider">
        <p class="line_left"></p>
        <div class="k3_text">
          <img src="images/tittle_spreated_k3.png" alt="" width="40" />
        </div>
        <p class="line_right"></p>
      </div>

      <div class="item">
        <div class="col">
          <img src="images/winner_left_icon.png" alt="" />
          <p class="title_win">Le montant du lot qui a été attribué</p>
          <p class="money">€ {{ cumulativeAmount }} EUR</p>
          <p class="text_win">Plus de 500 000 personnes ont gagné les lots</p>
        </div>
        <div class="col">
          <img src="images/winner_right_icon.png" alt="" />
          <p class="title_win">Gagnant du jour</p>
          <div ref="winners" class="daily_winner_detail">
            <ul>
              <li style="height: 100px" v-for="(item, index) in winners" :key="index">
                <p class="text_win">
                  Félicité à <span>{{ item.name }}</span> de gagner
                </p>
                <p class="money">€ {{ item.amount }} EUR</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    service: "service"
  },
  data() {
    return {
      cumulativeAmount: 0,
      winners: []
    };
  },
  methods: {
    async getWinnerInfoAsync() {
      var response = await this.service.getWinnerInfoAsync();
      if (response) {
        this.cumulativeAmount = response.cumulativeAmount;
        this.winners = response.winners;
      }
    }
  },
  async created() {
    await this.getWinnerInfoAsync();
  },
  watch: {
    winners: function (newVal) {
      this.$nextTick(function() {
        textslider(this.$refs['winners'], {
          direction: 'scrollUp',
          scrollNum: 1,
          scrollSpeed: 1000,
          pause: 10000
        });   
      }); 
    }
  }
};
</script>