import Vue from 'vue';
import VueRouter from 'vue-router';
import FranceView from './views/France';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'France',
    component: FranceView
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
