<template>
  <!-- 關於 -->
  <div class="about">
    <div class="container">
      <div class="title">À propos du <span>K3</span></div>
      <div class="k3_divider">
        <p class="line_left"></p>
        <div class="k3_text">
          <img src="images/tittle_spreated_k3.png" alt="" width="40" />
        </div>
        <p class="line_right"></p>
      </div>

      <div class="item">
        <div class="col">
          <div class="icon">
            <img src="images/aboutk3_game.png" alt="" />
          </div>
          <p class="title_text">Un jeu nouveau</p>
          <p class="text_content">Une expérience de loterie sans précédent</p>
        </div>
        <div class="col">
          <div class="icon">
            <img src="images/aboutk3_quick.png" alt="" />
          </div>
          <p class="title_text">Tirage rapide</p>
          <p class="text_content">
            Tirage tous les 5 minutes, l’on remporte rapidement le gros lot
          </p>
        </div>
        <div class="col">
          <div class="icon">
            <img src="images/aboutk3_fun.png" alt="" />
          </div>
          <p class="title_text">Un jeu intéressant</p>
          <p class="text_content">
            Une variété de mode de jeu et de pari permet au joueur d’avoir plein
            d’espoire en prenant un billet de loterie
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>