<template>
  <div class="history">
    <div class="container">
      <div class="title">Historiquedes <span>tirages du K3</span></div>
      <div class="k3_divider">
        <p class="line_left"></p>
        <div class="k3_text">
          <img src="images/tittle_spreated_k3.png" alt="" width="40" />
        </div>
        <p class="line_right"></p>
      </div>
      <div class="search">
        <select v-model="selYear" @change="updateDateSelect()">
          <option style="color: black;" v-for="year in yearList" :value="year" :key="year">{{ year }}</option>
        </select>
        <select v-model="selMonth" @change="updateDateSelect()">
          <option style="color: black;" v-for="(month, index) in monthText" :value="index" :key="index">{{ month }}</option>
        </select>
        <select v-model="selDay" @change="updateDateSelect()">
          <option style="color: black;" v-for="day in monthLen" :value="day" :key="day">{{ day }}</option>
        </select>
        <select v-model="selHour">
          <option style="color: black;" v-for="hour in hourLen" :value="hour - 1" :key="hour - 1">{{ padLeft(hour - 1, '0', 2) + ': 00'}}</option>
        </select>
        <div class="search_btn" @click="getData()">
          <i class="icon-search"></i>
        </div>
      </div>
      <div style="width: 800px;margin: 0 auto; position: relative;">
        <div class="list_title">
          <ul>
            <li>Heure</li>
            <li>Tirage</li>
            <li>Résultat du tirage</li>
            <li>Lots</li>
          </ul>
        </div>
      </div>  

      <div class="table">
        <div class="lds-css ng-scope" :style="'display:' + (showLoading ? '' : 'none')">
          <div style="width:'90%';height:'90%" class="lds-ellipsis">
            <div v-for="(index) in loadingPointCount" :key="index"><div></div></div>           
          </div>
        </div>
        <div class="list">
          <ul v-for="(item, index) in historyData" :key="index">
            <li>{{ item.issueTime }}</li>
            <li>{{ item.issueNo }}</li>
            <li>
              <ol v-for="(num, index2) in item.drawNumber" :key="index2">
                {{ num }}
              </ol>
            </li>
            <li>{{ sumDrawNumber(item.drawNumber) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const yearFrom = 2014;

export default {
  inject: {
    service: "service",
  },
  data() {
    return {
      yearList: [],
      monthLen: 0,
      monthText: [], 
      hourLen: 24,
      selYear: -1,
      selMonth: -1,
      selDay: -1,
      selHour: -1,
      historyData: [],
      showLoading: true,
      loadingPointCount: 5
    };
  },
  mounted() {
    let timeText = this.$momentTz.format('YYYY-MM-DD HH:00:00');     
    var currentTime = new Date(timeText);

    this.monthText = this.$moment.months(); 
    this.selYear = currentTime.getFullYear();
    this.selMonth = currentTime.getMonth();
    this.selDay = currentTime.getDate();
    this.selHour = currentTime.getHours();
    
    for (let i = yearFrom; i <= currentTime.getFullYear(); i++){
      this.yearList.push(i);
    }   
    
    for(let i = 0; i < this.monthText.length; i++){
      this.monthText[i] = this.capitalizeFirst(this.monthText[i]);
    }

    this.updateDateSelect();
  },
  methods: {
    updateDateSelect() {
      let timeText = this.$momentTz.format('YYYY-MM-DD HH:00:00');     
      var currentTime = new Date(timeText);

      let currentYear = currentTime.getFullYear();
      let currentMonth = currentTime.getMonth();
      let currentDay = currentTime.getDate();

      if (currentYear === this.selYear) {
        this.monthText = this.monthText.slice(0, currentMonth + 1);
        if (this.selMonth > currentMonth){
          this.selMonth = currentMonth;
        }
      } else {
        this.monthText = this.$moment.months();  

        for(let i = 0; i < this.monthText.length; i++){
          this.monthText[i] = this.capitalizeFirst(this.monthText[i]);
        }
      }
      
      if (currentYear === this.selYear && currentMonth === this.selMonth) {
        this.monthLen = currentDay;   
        if (this.selDay > currentDay) {   
          this.selDay = currentDay;
        }
      } else {               
        this.monthLen = new Date(this.selYear, this.selMonth + 1, 0).getDate();
      }

      if (currentYear === this.selYear && 
          currentMonth === this.selMonth && 
          currentDay === this.selDay) 
      {
        this.hourLen = currentTime.getHours() + 1;
        if (this.selHour > currentTime.getHours()) {   
          this.selHour = currentTime.getHours();
        }
      } else {
        this.hourLen = 24;
      }
    },  
    sumDrawNumber(drawNumber) {
      let val = 0;
      for (let i = 0; i < drawNumber.length; i++) {
        val += parseInt(drawNumber[i]);
      }
      return val;
    },
    capitalizeFirst(val){
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    async getData() {      
      if (this.selYear <= 0 || (this.selMonth + 1) <= 0 || this.selDay <= 0 || this.selHour < 0) return;      

      let queryDate = this.selYear + '-' + 
        this.padLeft((this.selMonth + 1), '0', 2) + '-' + 
        this.padLeft(this.selDay, '0', 2) + ' ' + 
        this.padLeft(this.selHour, '0', 2) + ':00:00'

      this.showLoading = true;
      var response = await this.service.getHistoryDataAsync(queryDate);     
      if (response) {
        this.showLoading = false;
        this.historyData = response;
      }
    }
  }, 
  watch: {
    "$store.state.issueNewest.k3": function (newVal) {
      this.historyData = newVal;
      this.showLoading = false;
    }
  }
};
</script>