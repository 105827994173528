<template>
  <div class="result">
    <div class="container">
      <div class="title">Dernier <span>résultat du tirage</span></div>
      <div class="k3_divider">
        <p class="line_left"></p>
        <div class="k3_text">
          <img src="images/tittle_spreated_k3.png" alt="" width="40" />
        </div>
        <p class="line_right"></p>
      </div>
      <div class="number">
        <li v-for="(item, index) in newestInfo.drawNumber" :key="index">{{ item }}</li>
      </div>
      <div class="tips">Tirage numéro: {{ newestInfo.issueNo }}</div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      newestInfo: {}
    };
  },
  methods: {},
  watch: {
    "$store.state.issueNewest.k3": function (newVal) {
      this.newestInfo = newVal[0];
    }
  }
};
</script>

