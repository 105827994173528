<template>
  <div class="security">
    <div class="container">
      <div class="col">
        <div class="title_divider">
          <p class="line_left"></p>
          <div class="text">Sécurité</div>
          <p class="line_right"></p>
        </div>
        <div class="">
          <img src="images/security_security.png" alt="" />
        </div>
      </div>
      <div class="col">
        <div class="title_divider">
          <p class="line_left"></p>
          <div class="text">Mode de paiement</div>
          <p class="line_right"></p>
        </div>
        <div class="">
          <img src="images/security_pay.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>