<template>
  <div>
    <menu-bar/>

    <banner/>
    <div class="divider"/>
    <newest-issue-info/>
    <div class="divider"/>
    <history/>
    <div class="divider"/>
    <winner/>
    <div class="divider"/>
    <about/>
    <div class="divider"/>
    <promise/>
    <div class="divider"/>
    <security/>
    <div class="divider"/>
  </div>
</template>

<script>
import MenuBar from "../components/Menu.vue";
import Banner from "../components/Banner.vue";
import NewestIssueInfo from "../components/NewestIssueInfo.vue";
import History from "../components/History.vue";
import Winner from "../components/Winner.vue";
import About from "../components/About.vue";
import Promise from "../components/Promise.vue";
import Security from "../components/Security.vue";

export default {
  components: {
    MenuBar,
    Banner,
    NewestIssueInfo,
    History,
    Winner,
    About,
    Promise,
    Security,
    Promise
  }
};
</script>