<template>
  <!-- Menu -->
  <div>
    <div class="menu">
      <div class="line"></div>
      <div class="buttons container">
        <li class="btn scrolltoa" @click="menuBtn('home')">Page d'accueil</li>
        <li class="btn scrolltob" @click="menuBtn('result')">Derniers résultats</li>
        <li class="btn scrolltoc" @click="menuBtn('history')">Historique des tirages du K3</li>
        <li class="btn scrolltod" @click="menuBtn('about')">À propos du K3</li>
        <li class="btn scrolltoe" @click="menuBtn('security')">Sécurité</li>
      </div>
      <div class="line"></div>
    </div>

    <div class="mobile_menu">
      <div class="line"></div>
      <div class="buttons container">
        <li class="mobile_btn scrolltoa" @click="menuBtn('home')">Page d'accueil</li>
        <li class="mobile_btn scrolltob" @click="menuBtn('result')">Derniers résultats</li>
        <li class="mobile_btn scrolltoc" @click="menuBtn('history')">Historique des tirages du K3</li>
        <li class="mobile_btn scrolltod" @click="menuBtn('about')">À propos du K3</li>
        <li class="mobile_btn scrolltoe" @click="menuBtn('security')">Sécurité</li>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {
    window.addEventListener("scroll", function (e) {        
        $('.menu').toggleClass('sticky', $(this).scrollTop() > 137);
        $('.banner').toggleClass('scroll', $(this).scrollTop() > 137);
    });
  },
  methods: {
    menuBtn: function (tag) {      
      let toPosY = 0;
      switch (tag){     
        case 'result':          
        case 'history':
        case 'about':         
        case 'security':
          toPosY = $("." + tag).offset().top - 63;
          break;
      }

      $('html, body').animate({
         scrollTop: toPosY
      }, 1000);

      $(".mobile_menu").slideToggle("slow");
      $(".icon-menu").toggleClass("active");
    }
  }
};
</script>