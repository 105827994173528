<template>
  <!-- 承诺 -->
  <div class="promise">
    <div class="container">
      <div class="title">Trois <span>garenties</span></div>
      <div class="k3_divider">
        <p class="line_left"></p>
        <div class="k3_text">
          <img src="images/tittle_spreated_k3.png" alt="" width="40" />
        </div>
        <p class="line_right"></p>
      </div>

      <div class="item">
        <div class="col">
          <div class="icon">
            <img src="images/promise_refund.png" alt="" />
          </div>
          <p class="title_text">Garantie de remboursement</p>
          <p class="text_content">
            Si vous n'êtes pas satisfait de notre service, K3 vous remboursera
            le montant total du premier achat.
          </p>
        </div>
        <div class="col">
          <div class="icon">
            <img src="images/promise_safe.png" alt="" />
          </div>
          <p class="title_text">Garantie de sécurité</p>
          <p class="text_content">
            Toutes les transactions procédées sur notre site sont protégées par
            la technologie SSL de 128 bits
          </p>
        </div>
        <div class="col">
          <div class="icon">
            <img src="images/promise_trust.png" alt="" />
          </div>
          <p class="title_text">Garantie de confiance</p>
          <p class="text_content">
            Les gains que vous avez remportés seront tels quels, just le bon
            montant
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>