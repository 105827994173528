<template>
  <div class="header">
    <div class="mR0_burger container">
      <div class="logo">
        <a href="javascript:void(0);"><img src="images/k3_logo.png" alt="" /></a>
      </div>
      <div class="burger">
        <i class="icon-menu" @click="openMenu()"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    openMenu() {
      $(".mobile_menu").slideToggle("slow");
      $(".icon-menu").toggleClass("active");
    }
  }
};
</script>